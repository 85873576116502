<template>
  <div class="main">
    <section class="hero-section   almost-full-screen">
      <div class="container">
        <div class="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-20">
          <div class="col-md-5 col-lg-5">
            <div class="hero-content-left section-heading ">
              <h1 style="font-weight:600">Grazie per aver compilato il form!</h1>
              <h4>
                Ti ricontatteremo al più presto.
              </h4>
              <p class="subtitle"><a href="mailto:marketing@elmec.it">marketing@elmec.it</a><br><a
                  href="tel:+390332802111"> +39 0332 802111</a></p>
            </div>
          </div>
          <div class="col-md-7 col-lg-7">
            <img class="img-fluid" src="img/thankyou.svg"
                 alt="survey image">
          </div>
        </div>
      </div>
      <div class="bottom-img-absolute d-none d-sm-block">
        <img style="padding-bottom: 30px" src="img/white_bg2.svg" alt="wave shape" class="img-fluid">
      </div>
    </section>
  </div>
</template>

<script>
  import {commonMixins} from '../mixins/common-mixins'

  export default {
    name: 'Preventivo',
    mixins: [commonMixins],
    components: {},
    mounted: function () {
      this.goTopPage()
    }
  }
</script>

<style scoped>

</style>
